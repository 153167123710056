<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="informationList.length">
                        <div v-for="(item,index) in informationList" :key="index" class="hot-topic-box" :class="{'mt20': index != 0}">
                            <hot-topic-item containerWidth="750px" width="220px" :item="item" class="bb" :showCollect="false" path="/organization/org-info-detail" :type="item.type">
                            </hot-topic-item>
                        </div>
                    </div>
                    <a-empty v-if="!informationList.length && !loading" />
                </a-spin>
                <div class="t-c mt30 info-pagination" v-if="infoCount > 0">
                    <a-pagination :total="infoCount" v-model:current="infoParams.page" :page-size="infoParams.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import storage from 'store'

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, hotTopicItem
    },
    data() {
        return {
            navList: orgDetailList(storage.get('commiittee_id')),//顶部菜单
            navCurrentId: 1,//当前页面菜单对应下标
            committee_id:'',
            informationList:[], //资讯列表
            orgContent:{},
            infoParams:{
                committee_id: storage.get('commiittee_id'),
                limit: 6,
                page: 1,
            },
            infoCount:0,
            loading: true,
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '即将召开会议', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-meeting?committee_id=' + this.$route.query.committee_id,
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                        committee_id:storage.get('commiittee_id')
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '回放视频', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-playback?committee_id=' + storage.get('commiittee_id'),
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id_search: storage.get('commiittee_id'),
                    },   // 参数条件
                },
                meetingAlbum: {
                    show: true, // 是否显示
                    title: '会议相册', // 标题
                    methods: 'post',
                    moreLink:'/organization/org-album?committee_id=' + storage.get('commiittee_id'),
                    data: {
                        page: 1,
                        limit: 1,
                        committee_id: storage.get('commiittee_id'),
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.pageInit()
    },
    methods: {
        // 资讯列表
        getInfomation(){
            this.request.post('GetNews',this.infoParams).then(res=>{
                this.informationList = res.data.list
            })
        },
        pageInit() {
            this.loading = true
            let reqArr = [
                this.request.post('GetNews',this.infoParams),
            ]
            Promise.all(reqArr).then(res => {
                // 资讯
                this.informationList = res[0].data.list
                this.infoCount = parseInt(res[0].data.count)
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.infoParams.page = page
            this.getInfomation()
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    .interview-list{
        min-height: 500px;
    }
    .bb {
        border-bottom: 1px solid #D8D8D8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }
}
.homepage-right {
    width:400px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 0 10px;
        width: 100%;
        .homepage-list{
            display: block;
            margin-right: 0;
            
            .interview-list{
                .hot-topic-box{
                    margin-top: 0;
                    .bb {
                        padding: 10px 0;
                        border-bottom: 1px solid #D8D8D8;
                    }
                    &:last-child{
                        .bb{
                            border-bottom: 0;
                        }
                    }
                }
            }
            .info-pagination{
                margin-top: 10px;
            }
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>