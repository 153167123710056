import { render, staticRenderFns } from "./org-infomation.vue?vue&type=template&id=1a6d8475&scoped=true&"
import script from "./org-infomation.vue?vue&type=script&lang=js&"
export * from "./org-infomation.vue?vue&type=script&lang=js&"
import style0 from "./org-infomation.vue?vue&type=style&index=0&id=1a6d8475&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6d8475",
  null
  
)

export default component.exports